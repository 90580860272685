import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const metaDesc =
  "Chris is an independent Visual Designer based in London. He combines raw aesthetics with refined interactions to create memorable digital experiences"
const metaAuthor = "@biron_io"

function SEO({ description, lang, meta, title, image }) {
  const metaDescription = description || metaDesc
  const t = `${title === "home" ? "Chris Biron" : `${title} | Chris Biron`}`

  const metaArray = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: t,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: metaAuthor,
    },
    {
      name: `twitter:title`,
      content: t,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    ,
  ]

  if (image) {
    metaArray.push({
      property: `og:image`,
      content: image,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={t}
      meta={metaArray.concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: "https://biron.io/chris-biron-meta.jpg",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
