import styled from "@xstyled/styled-components"

import { MainLink } from "@atoms/Typography"

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: header;
  padding: 2 0;
  color: light;
  mix-blend-mode: difference;
  isolation: unset;
`

export const H1 = styled.h1`
  position: absolute;
  text-indent: -9999px;
  z-index: -1;
`

export const StyledLink = styled(MainLink)``
