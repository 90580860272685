import React from "react"

import PropTypes from "prop-types"

import StyledPageWrap from "./styles"

const PageWrap = ({ children, as }) => {
  return (
    <StyledPageWrap as={as} px={{ xs: 2, md: 4 }}>
      {children}
    </StyledPageWrap>
  )
}

PageWrap.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
}

PageWrap.defaultProps = {
  as: "div",
}

export default PageWrap
