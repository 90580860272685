import styled, { breakpoints, css } from "@xstyled/styled-components"

export const MainCopy = styled.pBox`
  font-size: 16;
  font-family: 0;
  line-height: 1.1;
`

export const MainLink = styled.pBox`
  font-size: 0;
  font-family: 1;
  line-height: 1;
  text-transform: uppercase;
`

export const SecondaryCopy = styled.pBox`
  font-size: 7;
  font-family: 0;
  line-height: 1.1;
`

export const Quote = styled.blockquoteBox(
  breakpoints({
    xs: css`
      margin: 0;
      font-size: 7vw;
      line-height: 1.2;
    `,
    md: css`
      font-size: 5.1vw;
      line-height: 1.2;
    `,
  })
)
