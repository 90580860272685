import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@xstyled/styled-components"
import "modern-normalize/modern-normalize.css"

import Header from "@molecules/Header"

import theme from "@utils/theme"

import "lazysizes"

import "./base.css"

const Layout = ({ children, location }) => {
  const pathname = location && location.pathname
  const isHome = pathname === "/"

  return (
    <ThemeProvider theme={theme}>
      <Header isHome={isHome} />
      <main>{children}</main>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default Layout
