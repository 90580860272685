import React from "react"
import { Box } from "@xstyled/styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import PageWrap from "@atoms/PageWrap"

import { StyledHeader, StyledLink, H1 } from "./styles"

const Header = ({ isHome }) => {
  return (
    <>
      <StyledHeader isLight={isHome}>
        <PageWrap>
          <Box as="nav" row justifyContent="space-between">
            <StyledLink as={Link} to="/">
              index
            </StyledLink>
            <StyledLink as={Link} to="/about">
              info
            </StyledLink>
          </Box>
        </PageWrap>
      </StyledHeader>
      <H1>Chris Biron</H1>
    </>
  )
}

Header.propTypes = {
  isHome: PropTypes.bool,
}

Header.defaultProps = {
  isHome: false,
}

export default Header
